body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

:root {
  --mainColor: rgb(16, 60, 94);
  --bg-300: rgb(16, 60, 94, 15%);
  --black-color: rgb(0, 0, 0);
  --activetabs: rgb(205, 205, 205);
  --whiteColor: rgb(255, 255, 255);
  --borderColor: rgb(229, 229, 229);
  --red_color: rgb(255, 0, 0);
  --font-size-regular: 13px;
}

::-webkit-scrollbar {
  background-color: var(--transparent_color);
  width: 6px;
  height: 6px;
}

:hover::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

:not(.btn-check)+.btn:active:focus-visible {
  box-shadow: none;
}

.pointer {
  cursor: pointer;
}

:not(.btn-check)+.btn:active {
  background-color: var(--mainColor);
}

:hover::-webkit-scrollbar-thumb {
  background-color: var(--mainColor);
}

.black:hover::-webkit-scrollbar-thumb {
  background-color: var(--black-color);
}

.noscrollbar::-webkit-scrollbar {
  background-color: var(--transparent_color);
  width: 0px;
  height: 0px;
}

.noscrollbar:hover::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.form-check-input:checked {
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.form-check input {
  font-size: 17px !important;
}

p {
  font-size: 13px;
}

.form-select:focus,
.form-control:focus,
.css-t3ipsp-control {
  border-color: #dee2e6 !important;
  box-shadow: none !important;
}

.form-label {
  font-weight: 600;
}

.form-label,
.form-check,
.form-check .form-check-input {
  font-size: 13px !important;
}

.form-check .form-check-input {
  float: left;
  margin-left: -20px;
}

.ps-5 {
  padding-left: 2rem !important;
}

hr {
  opacity: 0.1;
}

.relative {
  position: relative;
}

.header-bg {
  background: var(--mainColor);
  color: var(--whiteColor);
}

.header-bg img {
  width: 25px;
  border-radius: 50%;
  height: 25px;
  object-fit: cover;
}

.header-bg .dropdown button {
  border: none;
  font-size: 12px;
  color: var(--whiteColor);
  padding: 5px;
}

.dataHistory {
  width: 200px;
  border-right: 1px solid var(--borderColor);
  background: var(--whiteColor);
  max-height: 250px;
  overflow: auto;
}

.dataHistory_darkMode,
.questionTabs .result__darkMode,
.dataHistory_darkMode select,
.dataHistory_darkMode textarea {
  background: #272822;
  color: #ffffff9e;
}

.questionTabs .result__darkMode {
  padding: 10px 15px;
}

.questionTabs .result__LightMode {
  padding: 10px 15px;
  background: var(--whiteColor);
}

.dataHistory_LightMode,
.dataHistory_LightMode select {
  background: var(--whiteColor);
}

/* .tabbingGroup{
  display: inline-flex;
  overflow: auto;
} */

.darkMode_light {
  background: #2f3129;
}

.dataHistory .historyList {
  padding: 6.5px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dataHistory .historyList:hover {
  cursor: pointer;
  background-color: #cdcdcd;
}

.dataHistory .historyList span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-item,
.dataHistory .historyList,
.questionTabs button,
.questionTabs span {
  font-size: 13px;
  transition: 1s;
}

.header-bg .dropdown .dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover,
.questionTabs button:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.questionTabs {
  width: min(600px, calc(70% + 100px));
  margin: 0 auto;
  position: relative;
}

.questionTabs .result {
  overflow: auto;
}

.questionTabs .result_height {
  height: calc(100vh - 205px);
}

.questionTabs .result p {
  font-size: 13px;
}

.questionTabs textarea {
  width: 100%;
  padding: 6px;
  border: 1px solid var(--borderColor);
  font-size: 13px;
  max-height: 35px;
  resize: none;
  background: transparent;
}

.questionTabs button,
.questionTabs select {
  border: 1px solid var(--borderColor);
  height: 34px;
  border-left: none;
}

.questionTabs button {
  padding: 0 10px;
  background: none;
}

.questionTabs select {
  font-size: 13px;
}

.questionTabs .result span {
  padding: 0px 5px 2px;
  border-radius: 4px;
  position: absolute;
  right: -25px;
}

.Footer_section {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
}

.div0 {
  width: 225px;
}

.resizable-x,
.resizable-y {
  overflow: hidden;
}

.resizable-x {
  height: 100%;
  padding: 5px;
}

.resizable-y {
  flex-direction: column;
}

.resizer-x,
.resizer-y {
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
}

.resizer-x {
  z-index: 2;
  cursor: e-resize;
  width: 5px;
  background: var(--whiteColor);
  z-index: 9;
}

.resizer-x::before,
.resizer-x::after {
  content: "";
  width: 1px;
  height: 16px;
  margin: 1px;
  background: rgb(115, 115, 115);
}

.resizer-y {
  z-index: 1;
  cursor: row-resize;
  flex-direction: column;
}

.resizer-y::before,
.resizer-y::after {
  content: "";
  width: 16px;
  height: 2px;
  margin: 2px;
  background: lightgray;
}

.resizable-y {
  flex: 80%;
}

section.middle-content {
  width: 100%;
}

section.middle-content .sidebar {
  width: 100%;
  border: 1px solid var(--borderColor);
  border-radius: 1px;
  overflow: auto;
  position: relative;
  height: calc(100vh - 129px);
  /* min-width: 231px; */
}

.sidebar__height {
  height: calc(100vh - 129px);
}

.sidebar .link__tab {
  border-bottom: 1px solid var(--borderColor);
  transition: 1s;
}

.sidebar .link__parent_tab {
  border-bottom: 1px solid var(--borderColor);
  transition: 1s;
}

.sidebar .link__tabs1 {
  border-bottom: 1px solid var(--borderColor);
  transition: 1s;
  padding: 7px 20px;
  position: sticky;
  top: 0;
  z-index: 9;
  background: var(--whiteColor);
}

.sidebar .link__tab:hover,
.sidebar .link__parent_tab .tab_urls:hover,
.sidebar .link__parent_tab:hover .tab_urls,
.sidebar .inaciveButton:hover,
.sidebar .link__tab.activee {
  /* background-color: #ebebeb; */
  cursor: pointer;
  transition: 1s;
  color: var(--mainColor);
  /* background: var(--bg-300); */
  background: #adbcc8;
  
}

/* .sidebar .link__tab:hover .tab_url,
.sidebar .active_Button:hover .activePackage,
.sidebar .link__tab.activee .tab_url,
.active_Button button:hover {
  color: var(--whiteColor);
} */

/* .btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: #103c5e !important;
} */

.tab_url i,
.link__tabs1 i,
.outputtool_point i {
  font-size: 12px;
}

.sidebar .link__tab .tab_url,
.sidebar .link__parent_tab .tab_urls {
  font-size: 12px;
  text-decoration: none;
  color: var(--mainColor);
  padding: 7px 20px;
  white-space: nowrap;
}

.cancel__cross {
  padding: 5px;
  height: 17px;
  align-items: center;
  justify-content: center;
  margin-left: 12px !important;
}

.cancel__cross:hover {
  background: #80808087;
  border-radius: 50%;
}

.table_handle_left {
  padding: 10px;
  font-size: 12px;
  width: 65%;
}

.table_handle_right {
  padding: 10px;
  font-size: 12px;
  width: 35%;
}

.table_height {
  height: calc(100vh - 223px);
  overflow: auto;
}

thead.thead-sticky {
  position: sticky;
  top: 0px;
}

.bottom_table {
  position: fixed;
  width: 100%;
  z-index: 9;
}

.table p,
.nomasg_tab p {
  margin: 0;
  font-size: 13px;
  white-space: nowrap;
}

.nomasg_tab {
  overflow: auto;
}

.table> :not(caption)>*>* {
  padding: 6px 0.5rem;
}

.background__color th {
  white-space: nowrap;
  font-size: 13px;
  background: rgb(235, 235, 235);
}

#basic-addon2 {
  font-size: 13px;
  color: white;
  background-color: var(--mainColor);
  border: 1px solid var(--mainColor);
}

.react-contextmenu-wrapper {
  font-size: 13px;
  font-weight: initial;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.react-contextmenu-wrapper .form-label {
  font-weight: 400;
}

.Footer_section h2 {
  font-size: 20px;
}

nav.react-contextmenu {
  min-width: 200px;
  border-radius: 4px;
  background-color: #040404d6;
  z-index: 99;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  overflow: hidden;
}

.react-contextmenu .react-contextmenu-item:hover {
  background: var(--black-color);
}

.react-contextmenu .react-contextmenu-item {
  padding: 7px 15px;
  cursor: pointer;
  border-bottom: 1px solid #3d3c3c;
}

.react-contextmenu .react-contextmenu-item:last-child {
  border-bottom: 0;
}

.react-contextmenu-item span {
  margin-left: 10px;
  font-size: 13px;
  color: #fff;
}

.react-contextmenu-item svg {
  font-size: 14px;
}

.react-contextmenu-item .edit {
  font-size: 13px;
  color: #fff;
}

.react-contextmenu-item .lock {
  font-size: 13px;
  color: gray;
}

.react-contextmenu-item .delete {
  font-size: 13px;
  color: #e94e51;
}

.react-contextmenu-item .green {
  font-size: 13px;
  color: rgb(0, 128, 0);
}

.fileReviewGreen {
  color: rgb(7, 145, 7) !important;
}

.fileReviewOrange {
  color: rgb(203, 143, 14) !important;
}

.fileReviewRed {
  color: rgb(203, 17, 14) !important;
}

.fileReviewGray {
  color: rgb(194, 194, 194) !important;
}

.react-contextmenu-item .history,
.active_Button .activePackage,
.active_Button button {
  font-size: 13px;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  border-color: transparent !important;
}

.output__section_widget {
  margin-right: 30px;
}

.outputShadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 15px 25px;
}

.outputShadowEditorTheme {
  box-shadow: rgb(253 247 247) 4px 0px 25px;
}

.CodePoint {
  height: calc(100vh - 226px);
  overflow: auto;
}

.outputtool_point {
  border-left: 1px solid var(--borderColor);
  position: absolute;
  right: 0px;
  background: var(--whiteColor);
  z-index: 99;
}

.outputtool_point ul {
  padding: 0;
}

.output__section_widget {
  position: relative;
  background: var(--whiteColor);
}

.outputtool_point__height,
.graphicalBar_height,
.graphicalBarCodePoint {
  height: calc(100vh - 197px);
}

.outputtool_point ul {
  border: none !important;
}

.outputtool_point ul li {
  padding: 8px;
  transition: 1s;
  line-height: 15px;
  text-align: center;
  justify-content: center !important;
  margin: 0 0 5px !important;
  list-style: none;
  cursor: pointer;
}

.outputtool_point ul li:hover,
.outputtool_point ul li.activee,
.console_log a.active,
.console_log a:hover,
.save_btn button:hover {
  background: var(--mainColor) !important;
  border-radius: 0 !important;
}

.outputtool_point ul li:hover i,
.outputtool_point ul li.activee i,
.console_log a.active,
.console_log a:hover,
.save_btn button:hover {
  color: var(--whiteColor) !important;
}

.output__section_widget_full_height {
  height: calc(100vh - 127px);
}

.output__section_widget_height,
.output__section_widget_full_height {
  overflow: auto;
  /* background: #ebebeb; */
}

.output__section_widget_height {
  height: calc(100vh - 235px);
}

.packageFullHeight {
  max-height: calc(100vh - 46px);
}

.packageSidebarFullHeight {
  max-height: calc(100vh - 87px);
}

.packageSidebaHeight {
  max-height: calc(100vh - 208px);
}

.packageFullHeight,
.packageheight,
.packageSidebarFullHeight,
.packageSidebaHeight {
  overflow-x: hidden;
}

.packageheight {
  max-height: calc(100vh - 167px);
}

.header_tool i,
.view__graphn {
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  margin: 0;
}

.tabSetting {
  border-left: 1px solid var(--borderColor);
  background: var(--whiteColor);
  padding: 2px;
  justify-content: flex-end;
  display: flex;
  margin-left: 5px;
  z-index: 9;
}

.tabSetting i {
  justify-content: center;
  align-items: center;
  float: inline-start;
  display: flex;
  height: 30px;
  width: 30px;
}

.header_tool .dropdown-toggle::after {
  display: none;
}

.ace-monokai .ace_print-margin,
.ace-chrome .ace_print-margin,
.ace-tm .ace_print-margin {
  background: transparent !important;
}

.ace-monokai,
.ace-chrome {
  width: 100% !important;
  height: 100% !important;
  max-height: calc(100vh - 110px) !important;
}

.editor_for_package .ace-monokai,
.editor_for_package .ace-chrome {
  height: 200px !important;
}

.ace_editor.ace_autocomplete {
  width: 300px !important;
  height: 140px !important;
}

.console_log {
  border-right: 1px solid #e5e5e5;
  overflow: auto;
  padding: 0;
}

.console_log_height {
  height: calc(100vh - 237px);
}

.console_log a {
  color: inherit !important;
  border-radius: 0 !important;
  font-size: 10px;
  padding: 5px 9px;
}

.resize-handle {
  cursor: ew-resize;
  height: 100%;
  width: 10px;
  position: absolute;
  left: 0;
  top: 0;
}

.study__detrails .form-select {
  --bs-form-select-bg-img: none;
  font-size: 13px;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border: 0;
  padding: 5px 15px 5px 10px;
  margin-right: -15px;
}

.study__detrails .form-select:focus {
  box-shadow: 0 0 0 0;
}

.modal_Heading {
  width: 100%;
  cursor: move;
}

.modal_Heading h5 {
  font-size: 14px;
  font-weight: bold;
}

.div1 {
  flex: 1 1;
  width: 100%;
  overflow: hidden;
}

.fullModeHeight {
  height: 98vh !important;
  overflow: hidden !important;
}

.dashboardFullHeight {
  height: 100vh !important;
  overflow: auto;
}

.fullModeairequest_height {
  height: calc(100vh - 40px);
  overflow: auto;
}

.result.fullModeresult_height {
  height: calc(100vh - 110px);
}

.openfilewrapper,
.findWrapper {
  position: relative;
}

.openfilewrapper input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}

.findWrapper .findandreplce {
  position: absolute;
  right: 45px;
  top: 15px;
  z-index: 9;
  width: 335px;
  padding: 5px;
  font-size: 13px;
}

.findWrapper .findandreplce__darkMode {
  background: #2f3129;
  color: var(--whiteColor);
}

.findWrapper .findandreplce__whiteMode {
  background: #ebebeb;
  color: #212529;
}

.findandreplce i {
  padding: 3px;
  border-radius: 4px;
  width: 18px;
  align-items: center;
  justify-content: center;
}

.findandreplce input {
  margin-bottom: 3px;
  color: var(--whiteColor);
}

.findandreplce input.darkMode {
  background: var(--black-color);
  border: 1px solid var(--black-color);
}

.findandreplce input.whiteMode {
  background: var(--whiteColor);
  border: 1px solid var(--borderColor);
  color: var(--black-color);
}

input.goto_class {
  width: 40px;
  font-size: 12px;
  border: 1px solid #c7c8c9;
  border-radius: 4px;
}

.line__output {
  padding: 5px 5px 5px 40px;
  font-size: 13px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.dark_mode_output {
  background: #ebebeb;
  color: var(--black-color);
}

.light_mode_output {
  background: #2f3129;
  color: #ddd;
}

.informationTable {
  overflow: auto;
}

.informationTable p {
  font-size: 13px;
  margin: 0;
  white-space: nowrap;
}

.informationTable i {
  position: absolute;
  left: -7px;
  top: 9px;
  color: red;
  font-size: 10px;
  background: transparent;
  transition: 1s;
  border-radius: 50%;
  width: 13px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.informationTable i:hover {
  color: white;
  background: red;
}

.informationTable input {
  width: 100%;
  border: 0;
  font-size: 13px;
  padding: 6px 8px;
}

.informationTable input.bold {
  font-weight: bold;
}

.tabDesign {
  gap: 10px;
}

.tabHeader {
  border: 1px solid var(--borderColor);
  padding: 0 10px;
  /* background: var(--whiteColor); */
  background: #dee2e6;
  height: 27px;
  border-radius: 5px 5px 0 0;
  min-width: 150px;
  border-bottom: 0;
}

.tabHeader button,
.tabHeaderActive button {
  border: none;
  background: transparent;
  padding: 0;
  /* color: white; */
}

.tabHeader p {
  font-size: 13px;
  margin: 0;
}

.save_btn button {
  border: 1px solid var(--mainColor);
  background: none;
  transition: 1s;
  border-radius: 3px;
  padding: 5px 21px;
  font-size: 13px;
}

.view__graphn:hover,
.activee,
.graphicalBar li:hover {
  background: #ebebeb;
}

.graphicalBar li {
  padding: 4px 7px;
}

.tabHeaderActive i{
  color: white !important;
}

.tabHeaderActive i:hover{
  color: black !important;
}

.activee_history,
/* .tabHeader:hover, */
.tabHeaderActive,
.header_tool i:hover,
.questionTabs span:hover,
.activee__icon {
  /* background: var(--activetabs);
  color: var(--black-color); */
  background: var(--mainColor) !important;
  color: #fff;
}

.menus .dropdown-toggle {
  /* background: var(--activetabs) !important; */
  background: #dee2e6;
  padding: 1px 10px;
  margin: 0px 5px -3px;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  border: 1px solid var(--borderColor);
  border-bottom: 0;
}

.search_tabs {
  position: relative;
}

.search_tabs I {
  position: absolute;
  top: 15px;
  font-size: 13px;
  left: 10px;
}

.search_tabs input {
  border: none;
  font-size: 13px;
  padding: 10px 0px 10px 30px;
  width: 100%;
}

.search_tab_section .dropdown-menu.show {
  min-width: 260px;
  padding: 0;
  overflow: hidden;
}

.close_x i,
.tabHeader button i {
  border-radius: 50%;
  padding: 1px 0px 0px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 10px;
}

.close_x i:hover,
.tabHeader button i:hover,
.findandreplce i:hover {
  background: var(--whiteColor);
  color: var(--black-color);
}

.graphicalBar {
  border-right: 1px solid var(--borderColor);
  background: #f4f4f4;
}

.listing_output {
  height: calc(100vh - 300px);
  overflow-x: hidden;
}

.nodes_listing {
  text-align: center;
  margin: 20px 0px 0px;
}

.nodes_listing p {
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 600;
}

.nodes_listing i {
  padding: 10px;
  background: var(--black-color);
  border-radius: 4px;
  color: var(--whiteColor);
}

.ant-modal-content {
  border-radius: 5px 5px 0 0 !important;
  padding: 0 !important;
}

.ant-modal-header {
  padding: 18px 10px 5px !important;
  margin: 0 !important;
  border-bottom: 2px solid var(--borderColor) !important;
  background-color: rgb(219 231 238) !important;
  border-radius: 4px 4px 0 0 !important;
}

.ant-modal-body {
  height: auto;
  overflow: auto;
}

.ant-modal-body .common_css {
  padding: 0 20px !important;
  margin: 20px 0;
}

.ant-modal-body .form-control {
  padding: 5px 10px;
  font-size: 13px;
}

.footer_btn {
  background: #dbe7ee;
  border-radius: 0 0 4px 4px;
  z-index: 9;
  position: absolute;
  width: 100%;
}

.ant-modal-wrap {
  overflow: hidden !important;
}

.highlightOption {
  background: var(--mainColor) !important;
  font-size: 12px;
  color: var(--whiteColor) !important;
}

.multiSelectContainer li.option {
  font-size: 12px;
}

.notFound {
  background: var(--red_color) !important;
  font-size: 12px;
  color: var(--whiteColor) !important;
}

.multiSelectContainer li,
.notFound {
  padding: 4px !important;
}

.form-switch .form-check-input {
  width: 30px !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.ql-container {
  border: 1px solid var(--borderColor) !important;
  border-top: 0 !important;
  border-radius: 0 0 5px 5px;
  height: 250px !important;
  overflow: auto;
}

.ql-snow .ql-picker-label {
  font-size: 13px;
}

.ql-toolbar.ql-snow {
  border: 1px solid var(--borderColor) !important;
  border-radius: 5px 5px 0 0;
}

.ql-toolbar.ql-snow .ql-picker-options {
  box-shadow: none !important;
  border-radius: 3px;
}

.css-1fdsijx-ValueContainer {
  font-size: 13px;
}

.css-13cymwt-control {
  border: 1px solid var(--borderColor) !important;
  border-radius: 5px !important;
}

.css-1nmdiq5-menu {
  display: block !important;
}

.css-t3ipsp-control:hover {
  border-color: var(--mainColor) !important;
}

/* =============================================
 ================ Satish Css ===================
================================================*/

.account_detai h6 {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  padding: 10px 5px;
  border-radius: 4px;
  font-size: 12px;
}

.button_type button {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  padding: 5px 20px;
  border-radius: 4px;
  font-size: 13px;
  border: none;
  margin-bottom: 10px;
}

.button_type34 button {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  padding: 4px 52px;
  border-radius: 4px;
}

.button_type34 {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.button_types {
  font-size: 12px;
  overflow: hidden;
  display: inline-block;
}

.button_types input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.button_types button {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  padding: 4px 20px;
  border-radius: 4px;
}

.upload_details1 {
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 10px;
}

.upload_details1 h6 {
  text-align: center;
  font-size: 12px;
}

.upload_details1 p {
  text-align: center;
  font-size: 12px;
}

.details_data1.height_scroll {
  height: calc(100vh - 300px);
  overflow-x: hidden;
}

.details_data1 {
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 10px;
  border-radius: 5px;
  background: var(--whiteColor);
}

.details_data1 h6 {
  font-size: 12px;
}

.details_data1 input,
.details_data1 textarea {
  font-size: 12px;
}

.details_data1 select {
  font-size: 12px;
}

.common_css select {
  font-size: 12px;
}

.common_css h6 {
  font-size: 13px;
}

.common_css span {
  color: red;
}

.details_data1.height_scroll {
  height: calc(100vh - 300px);
  overflow-x: hidden;
}

.icon_set_auth i {
  font-size: 100px;
  color: #e94e51;
}

.icon_set_auth {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.ant-modal-footer {
  display: none;
}

.all_package_data {
  display: flex;
  gap: 10px;
  font-size: 13px;
}

.all_package_data span {
  font-size: 12px !important;
  color: green;
}

.whole_details_package {
  box-shadow:
    rgb(0 0 0 / 0%) 0px 0.0625em 0.0625em,
    rgb(0 0 0 / 9%) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  border-radius: 4px;
  padding: 14px;
}

.whole_details_package h6 {
  font-size: 17px !important;
}

.jason_data {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
}

.whole_details_package i {
  margin-left: 4px;
  color: green;
  font-size: 12px;
}

.tabbibg_type {
  display: flex;
  gap: 5px;
}

.tags_name h3 {
  font-size: 14px;
  margin-left: 15px;
}

.taglist_type .tag {
  background-color: #ebebeb !important;
  padding: 7px 10px;
  border-radius: 4px;
  color: var(--black-color);
  font-size: 12px !important;
  font-weight: 400;
  margin: 5px 5px 5px 0px;
  border: 1px solid #727272;
}

.taglist_type .tag:hover,
.taglist_type .activetagfilter {
  background-color: #727272 !important;
  color: var(--whiteColor);
  border: 1px solid #727272;
  transition: 1s;
}

.whole_details_package a {
  text-decoration: none;
  color: var(--black-color);
}

.tabbibg_type p {
  background-color: #f2f2f2;
  padding: 2px 15px 2px;
  border-radius: 4px;
  font-size: 12px !important;
}

.tabbibg_type p:hover {
  background-color: #cdcdcd;
  transition: 1s;
}

/* .whole_details_package .badge {
  background-color: #f2f2f2 !important;
  border-radius: 4px;
  font-size: 12px !important;
  color: var(--black-color);
  font-weight: 400;
  padding: 5px 15px;
}

.whole_details_package .badge:hover {
  background-color: #cdcdcd !important;
  transition: 1s;
} */

.Name_section1 {
  color: green !important;
}

.Name_section1:hover {
  color: #e94e51 !important;
}

.whole_sectionJ_jason p {
  margin-bottom: 12px;
  font-weight: 400;
}

.whole_sectionJ_jason {
  height: calc(100vh - 164px);
  overflow: auto;
}

.packages_details {
  gap: 20px;
}

.all_packageslist {
  justify-content: space-evenly;
}

.activeTab {
  border-bottom: 3px solid var(--black-color);
  font-size: 14px;
  font-weight: 600;
}

.inactiveTab {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 600;
}

.underline {
  border: none;
  border-bottom: 1px solid var(--black-color);
  transition: border-color 0.3s ease;
  margin: 0;
}

.search-container {
  width: 75%;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.search-icon {
  margin-right: 5px;
  color: #888;
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
}

.search-input::placeholder {
  color: #aaa;
}

.search-container {
  align-items: center;
  position: relative;
  margin-left: 10%;
}

.search-box {
  align-items: center;
  padding: 5px;
  width: 100%;
}

.search-text {
  position: absolute;
  right: 0;
  background-color: var(--mainColor);
  color: white;
  padding: 5px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.versions_type span {
  padding: 4px 10px;
}

.search-text:hover {
  background-color: #0973b0;
  transition: 1s;
}

.main_partsof h1 {
  font-size: 15px;
  color: rgb(17, 17, 17);
  line-height: 1.25;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}

/* .main_partsof h3 {
  color: rgb(117, 117, 117);
  font-size: 14px;
  margin-top: 0.5rem;
} */

.main_partsof th {
  font-weight: 600;
  font-size: 14px;
}

.main_partsof td {
  font-size: 13px;
}

.npm_parts {
  justify-content: space-between;
}

.npm_parts p i {
  color: var(--black-color);
  font-size: 14px;
  margin-right: 4px;
}

.npm_parts {
  border: 1px solid var(--black-color);
  border-radius: 4px;
}

.npm_parts p {
  margin: 10px;
}

.npm_set {
  background-color: #5b5b5b;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ver,
.npm_set,
.artist,
.passing {
  color: var(--whiteColor);
  font-size: 13px;
}

.ver,
.artist,
.passing {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ver {
  background-color: #0b7dbe;
}

.artist {
  background-color: #89b701;
}

.passing {
  background-color: #29ab47;
}

.require h3 {
  color: #111;
  font-weight: 600;
}

.require p {
  color: #333;
  font-size: 13px;
}

.require h4 {
  font-size: 13px;
}

.list_type {
  font-size: 1em;
  border-radius: 2px;
  background: #f7f7f7;
  padding: 0px 5px;
}

.npm_sas {
  font-size: 1em;
  border-radius: 2px;
  background: #f7f7f7;
  padding: 10px 5px;
}

._704c034e .highlight {
  background-color: #f0f0f0;
  color: var(--black-color);
  margin-bottom: 16px;
  border-radius: 6px;
}

._6d9832ac pre {
  margin-top: 0;
  margin-bottom: 24px;
  padding: 12px;
  font-size: 1em;
  background: #f7f7f7;
  border-radius: 2px;
  overflow-x: auto;
}

.pl-k {
  color: #d73a49;
}

.node_verge {
  color: #29ab47;
}

.docu {
  font-weight: bolder;
  color: #cb3837;
  line-height: 1.4;
}

.ul_li ul li p {
  font-size: 13px;
}

.ul_li ul li {
  list-style: none;
  line-height: 30px;
}

.repos ul {
  color: var(--black-color);
}

.repos button {
  border: none;
  font-size: 13px;
  padding: 3px 14px 3px;
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 6px;
  font-weight: 500;
}

.repos button:hover {
  background-color: #c6c2c2;
  transition: 1s;
}

.repos ul li {
  font-size: 13px !important;
}

.repos_t {
  justify-content: space-between;
}

pre {
  margin-bottom: 5px;
}

.td {
  font-size: 13px;
}

.try_on h6 {
  background-image: linear-gradient(-180deg,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(0, 184, 15, 0.1) 100%);
  border: 1px solid rgba(75, 173, 58, 0.5);
  color: var(--black-color);
  text-align: center;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.try_on p {
  background-image: linear-gradient(-180deg,
      rgba(255, 255, 255, 0.09) 0%,
      rgba(255, 89, 89, 0.1) 97%);
  border: 1px solid #f1b8bc;
  color: var(--color-fg-danger);
  text-align: center;
  padding: 5px;
  border-radius: 4px;
}

.links_dev ul li a {
  text-decoration: none;
  color: #cb3837;
  font-size: 14px;
}

.links_dev li {
  margin-left: 10px;
  font-weight: 600;
}

.inputforSecelct .input-group-text {
  padding: 2px 10px;
  font-size: 13px;
  background: var(--mainColor);
  color: var(--whiteColor);
  border: 1px solid var(--mainColor);
}

.repos svg {
  height: 15px !important;
  margin-right: 5px;
}

.whole_packs1 {
  height: calc(100vh - 212px);
  overflow: auto;
  border-right: 1px solid #e5e5e5;
  padding: 12px;
}

.fullmodeheightscroll {
  height: calc(100vh - 116px);
  overflow: auto;
}

.icon_tech {
  font-size: 9px;
  color: #29ab47;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: visible;
  position: absolute;
  z-index: 1;
  top: 125%;
}

#button-tooltip i {
  color: green !important;
}

.icon_valids i {
  color: #00000091 !important;
  font-size: 13px !important;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
}

.store_table {
  position: relative;
  font-size: 13px;
}

.fav_table {
  position: relative;
  font-size: 13px;
  margin-right: 13px !important;
}

.nums_badge {
  position: absolute !important;
  top: 49px !important;
  right: 17px;
  font-size: 9px;
  background-color: white !important;
  color: var(--mainColor);
  padding: 2px 3px 2px;
}

.number_badge {
  position: absolute !important;
  top: -6px !important;
  right: -11px;
  font-size: 13px;
}

.cart_datas .number_badge {
  background-color: white !important;
  color: var(--mainColor);
  padding: 2px 3px 2px;
  font-size: 9px;
  margin-top: 5px;
  right: -3px;
}

.log_change {
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  line-height: 29px;
  padding: 10px;
  font-size: 14px;
  background: #ebebeb;
}

.log_change pre {
  white-space: normal;
}

.historyList h3 {
  font-size: 13px;
}

.whole_sidepack {
  height: calc(100vh - 200px);
  overflow: auto;
}

.buttons1 {
  gap: 20px;
}

.frees {
  border: none;
  background-color: #bfbbbb !important;
  border-radius: 4px;
  padding: 2px 18px;
}

.frees:hover {
  background-color: #8d8989 !important;
  transition: 1s;
}

.typefilter {
  border: none;
  background-color: #bfbbbb !important;
  border-radius: 4px;
  padding: 1px 18px;
}

.typefilter:hover {
  background-color: #727272 !important;
  color: var(--whiteColor);
  font-weight: 600;
  transition: 1s;
}

.activetypefilter {
  background-color: #727272 !important;
  color: var(--whiteColor);
  font-weight: 600;
  transition: 1s;
}

.whole_packs1 a {
  text-decoration: none;
  color: var(--black-color);
}

.whole_packs1 i {
  color: green;
  font-size: 14px;
}

.historyList option {
  font-size: 13px !important;
}

.historyList label {
  font-size: 13px !important;
}

.historyList .form-select {
  font-size: 13px;
}

.info_tic {
  font-size: 10px !important;
  border: 1px solid var(--black-color);
  color: #000 !important;
  border-radius: 100%;
  padding: 1px 5px;
}

.mrp_section {
  color: #aaa;
}

.off_type {
  color: #29ab47;
  font-size: 16px !important;
  font-weight: bold;
}

.off_span {
  position: relative;
}

.off_span:hover::after {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f9f9f9;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1;
  white-space: nowrap;
}

.off_span {
  border: 1px solid #aaa;
  color: #aaa;
  font-weight: 500;
  padding: 1px 6px 1px;
  font-size: 10px;
  border-radius: 2px;
}

.version_icons {
  color: #00000091 !important;
  font-size: 13px !important;
}

.store_drop .dropdown-toggle::after {
  display: none;
}

.add_pack_buy i {
  color: var(--mainColor) !important;
  margin-right: 4px;
  font-size: 12px;
}

.store_drop .dropdown-menu {
  display: none;
}

.store_drop:hover .dropdown-menu {
  display: block;
}

.cart_sell {
  justify-content: space-between;
  font-size: 13px !important;
  line-height: 18px;
}

.add_carts1 {
  justify-content: end !important;
  gap: 10px;
  margin: 5px;
}

.add_carts1 i {
  color: var(--mainColor) !important;
  font-size: 13px;
}

.fov_view i {
  color: var(--mainColor) !important;
  font-size: 13px;
}

.add_carts1 button {
  color: #000 !important;
  border-radius: 4px;
  font-weight: 500;
  padding: 5px 10px 5px !important;
  border: 0;
  font-size: 12px;
}

.add_carts1 button:hover {
  background-color: #c6c2c2;
  transition: 1s;
}

.fov_view button {
  color: #000 !important;
  border-radius: 4px;
  text-align: center;
  padding: 5px 10px 5px !important;
}

.fov_view {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.fov_view button:hover {
  background-color: #c6c2c2;
  transition: 1s;
}

.title_pack {
  width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.heading_favs {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.drop_list_group {
  width: 400px !important;
  padding: 0;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px !important;
}

.drop_list_group td {
  font-size: 14px;
}

.drop_list_group td,
.drop_list_group th {
  font-size: 13px;
}

.drop_list_group th {
  font-weight: 600;
}

.totals {
  font-weight: bold;
}

.icon_sets1 {
  gap: 26px;
}

.icon_sets1 i {
  color: var(--mainColor) !important;
  margin: 0;
}

.inreview_class {
  color: #00000091 !important;
}

.common_flexcss,
.resizable-x,
.npm_parts,
.versions_type,
.repos_t,
.packages_details,
.all_packageslist,
.search-container,
.search-box,
.resizer-x,
.resizer-y,
.cancel__cross,
.findandreplce i .resizable-y,
.googletab a,
.icon_sets1,
.add_carts1,
.cart_sell,
.buttons1,
.links_dev ul,
.input-group-text {
  display: flex;
}

.common_curser_pointer,
.icon_sets1 i,
.whole_packs1 i,
.historyList option,
.historyList label,
.info_tic,
.icon_sets1 i,
.Name_section1,
.all_packageslist,
.try_on h6,
.try_on p,
.nodes_listing i,
.whole_details_package h6,
.tag,
.tabbibg_type p,
.study__detrails .form-select,
.openfilewrapper input,
.findandreplce i,
.informationTable i,
.tabHeader,
.whole_details_package .badge,
.questionTabs .result span,
.icon_valids i,
.search_tabs i {
  cursor: pointer !important;
}

/* loginpage css */
.Welcome_to h2 {
  font-weight: 500;
  color: var(--mainColor);
  text-align: center;
  font-size: 23px;
}

.button_sign_log {
  width: 100%;
  color: white;
  padding: 4px;
  border: 2px solid var(--mainColor);
  border-radius: 5px;
  transition: background-color 0.3s ease;
  background-color: var(--mainColor);
  font-size: var(--font-size-regular);
}

.Welcome_to p {
  font-size: 13px;
  color: var(--mainColor);
  font-weight: 400;
}

.logo_image img {
  position: fixed;
  left: 67px;
  height: 50px;
  width: 50px;
  border-radius: 4px;
}

.login_box a,
.form_details a {
  text-decoration: none;
}

.form_details .form-control,
.form_details .form-select,
.forms_details .form-control,
.form-control,
.form-select {
  font-size: 12px;
}

.inputforSecelct .input-group-text {
  border-radius: 3px;
}

.inputforSecelct .input-group .form-control,
.inputforSecelct .input-group .form-select {
  padding: 0 10px;
}

.form_details {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  place-self: center;
  width: 65%;
  padding: 25px;
  border-radius: 4px;
}

.total_analysis {
  width: 73%;
  min-height: 100vh;
  display: grid;
  margin: auto;
}

.sign_url {
  width: 73%;
  min-height: 100vh;
  display: grid;
  margin: auto;
}

.shadow_type {
  place-self: center;
  width: 33%;
  border-radius: 4px;
}

.log_url {
  width: 100%;
  min-height: 100vh;
  display: grid;
  margin: auto;
}

.forms_details {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  place-self: center;
  width: 25%;
  padding: 25px;
  border-radius: 4px;
}

.sub_buttons button {
  color: white;
  padding: 4px;
  width: 20%;
  border: 2px solid var(--mainColor);
  border-radius: 5px;
  transition: background-color 0.3s ease;
  background-color: var(--mainColor);
}

.sub_buttons {
  justify-content: flex-end !important;
}

.otp-input-fields input {
  width: 49px;
}

/* Table css */
.table-container table th,
.table-container table td,
.table_content td {
  font-size: 12px;
}

.table-container td {
  padding: 5px 10px !important;
}

.table-container table {
  margin-bottom: 0 !important;
}

.tableLibrary.custom-table th:last-child {
  width: 100% !important;
  table-layout: fixed;
}

.tableLibrary.custom-table {
  width: auto;
  max-width: max-content;
}

.custom-table {
  width: 100%;
  table-layout: fixed;
}

.custom-table.resizing {
  width: 100%;
  max-width: fit-content;
}

.custom-table th {
  resize: horizontal;
}

.custom-table th,
.custom-table td {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}

.headerFixed {
  position: sticky;
  top: 0;
}

.table_content {
  max-height: calc(100vh - 255px);
}

.table_content th {
  background-color: var(--mainColor);
  background-color: #b8cfdf;
  /* color: var(--whiteColor); */
  color: black;
  font-weight: 500;
  font-size: 13px;
}

.css-1n5o7vh-diff-container pre {
  line-height: 14px !important;
}

.base_analyse {
  background-color: var(--mainColor);
  padding: 10px 0;
}

.base_analyse h6 {
  font-size: 15px;
  font-weight: 600;
  color: var(--whiteColor);
}

.base_analyse p {
  font-size: 14px;
  color: var(--whiteColor);
  margin: 0;
}

.base_analyse .form-control {
  padding: 4.2px;
  font-size: 13px;
  border-right: 1px solid #dee2e6;
}

.ulHeight {
  height: 200px;
  overflow: auto;
}

.widget-data {
  padding: 0 15px;
}

.widget-data p {
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}

.icon {
  width: 40px;
  height: 40px;
  background: var(--mainColor);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  color: var(--whiteColor);
}

.graph__title p {
  font-size: 15px;
  font-weight: 600;
}

.graph__title button {
  font-size: 13px;
  border: none;
  background-color: #dbe7ee;
  font-weight: 500;
}

.card {
  background: rgb(2 88 138 / 14%);
  border-radius: 3px;
}

.coupon_code {
  padding: 4px 42px;
}

.coupon_mark p {
  font-size: 13px;
}

.qr__imag {
  display: flex;
  justify-content: center;
}

.qr__imag img {
  width: 225px;
}

.qr__imag .security-shield,
.qr__imag img {
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 #63636333;
}

.qr__imag .security-shield {
  padding: 20px 50px;
  text-align: center;
}

.qr__imag .security-shield i {
  font-size: 150px;
}

.otp-input-fields input {
  height: 35px;
  width: 35px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--mainColor);
  text-align: center;
  outline: none;
  font-size: 16px;
}

.otp-input-fields {
  width: auto;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  margin: 0 auto;
}

.password_visible i {
  position: absolute;
  right: 6px;
  top: 10px;
  font-size: 13px;
  z-index: 9;
  cursor: pointer;
}

.avatar-upload {
  position: relative;
  max-width: 190px;
  margin: 0px auto;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 40px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input+label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 50%;
  background: white;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input+label:hover {
  background: rgb(72, 72, 72 /10%);
  border-color: rgb(72, 72, 72 /10%);
}

.avatar-upload .avatar-edit input+label:after {
  content: "\f040";
  font-family: "FontAwesome";
  color: var(--black-color);
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 155px;
  position: relative;
  border-radius: 100%;
  border: 1px solid rgb(222 226 230);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview img {
  border-radius: 50%;
  object-fit: cover;
  height: 155px;
  width: 155px;
}

.sign_url .form-label span {
  color: red !important;
}

/* Profile CSS  */
.profile .profile_dd {
  width: 170px;
  position: absolute;
  top: 29px;
  background: var(--whiteColor);
  z-index: 99;
  overflow: hidden;
  border-radius: 5px;
}

.icon_wrap .title_name:hover,
.profileNameActive {
  background: var(--whiteColor);
  border-radius: 4px;
  color: var(--mainColor);
  transition: 1s;
}

.title_name i {
  font-size: 10px;
  margin-top: 5px;
}

.profile .profile_dd ul li {
  border-bottom: 1px solid #80808030;
}

.profile .profile_dd ul li:last-child {
  border-bottom: 0;
}

.profile .profile_dd ul li a {
  display: block;
  padding: 6px 15px;
  position: relative;
  font-weight: 500;
  font-size: var(--font-size-regular);
  color: var(--mainColor);
  text-decoration: none;
}

.profile_ul li:last-child .logout-link:hover {
  background-color: rgba(255, 0, 0, 0.073);
}

.profile .profile_dd ul li:hover {
  color: var(--mainColor);
  background: var(--bg-300);
}

.profile.active .profile_ul {
  max-height: 300px;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}

.profile_ul {
  padding: 0;
  overflow: hidden;
  margin: 0;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

:disabled {
  cursor: no-drop;
  background-color: var(--bs-secondary-bg) !important;
  color: var(--bs-body-color) !important;
  border: 1px solid #bbbbbb !important;
}

.box .form-check-input {
  font-size: 16px;
  margin-top: 2px;
}

.box span {
  font-weight: 500;
  color: var(--black-color);
  margin-left: 8px;
  font-size: 12px;
}

.dashboard_Section_height {
  height: calc(100vh - 165px);
  overflow-x: hidden;
}

.dashboard_Section_height .card-body {
  padding: 17px 13px;
}

/* table_header css */
.button_group button:hover i,
.close_circle:hover i,
.user_filter button:hover i,
.icon {
  color: rgb(255, 255, 255) !important;
}

.user_filter input {
  width: 125px;
}

.user_filter input {
  border-radius: 3px 0px 0px 3px;
  border-right: 0 !important;
  height: 30px;
  font-size: 12px;
  border: 1px solid var(--mainColor);
}

.user_filter button {
  width: 30px;
  height: 30px;
  padding: 0px;
  border-radius: 3px;
  background: transparent;
  border: 1px solid var(--mainColor);
  transition: 1s;
}

.user_filter button i {
  font-size: 12px;
  color: var(--mainColor);
  background: transparent;
}

.user_filter .form-select {
  border-radius: 4px !important;
}

.button-count {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: var(--mainColor);
  color: rgb(255, 255, 255);
  font-size: 15px;
  text-align: center;
  border: 1px solid var(--mainColor);
  outline: none;
}

.button-count:active,
.user_filter button:hover {
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.number-credits {
  width: 46px;
  height: 28px;
  font-size: 13px;
  border: 1px solid rgb(0 97 154 / 5%);
  text-align: center;
  padding: 14.4px 0 13.7px;
}

.sidebar_Content {
  overflow: auto;
  position: relative;
}

.sidebar_Content textarea {
  height: calc(100vh - 624px);
  overflow: auto;
  resize: none;
}

.modal-header .close_modal {
  transition: 1s;
  color: rgb(255, 0, 0);
  background: white;
  border-radius: 50%;
  font-size: 11px;
  border: 1px solid rgb(255, 0, 0);
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  cursor: pointer;
}

.modal-header .close_modal:hover {
  transform: rotate(180deg);
  color: red;
}

.analytic_part,
.list_analytic {
  border-bottom: 1px solid #5745453d;
}

.list_analytic ul li {
  font-size: 13px;
}

.list_analytic ul {
  height: 80px;
  overflow: auto;
}

.activity__section li {
  background: #f2f2f2;
  padding: 4px 10px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin-top: 10px;
}

.pagination li {
  display: inline-block;
  margin-left: 5px;
}

.pagination li a {
  color: var(--black-color);
  padding: 5px 10px;
  border: 1px solid var(--borderColor);
  text-decoration: none;
  font-size: 13px;
  border-radius: 4px;
  margin-right: 5px;
  background: var(--whiteColor);
}

.pagination li.active a {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.pagination li a:hover {
  background-color: var(--mainColor);
  transition: 1s;
  color: var(--whiteColor);
}

.pagination .break-me {
  cursor: auto;
}

.pagination .break-me a {
  pointer-events: none;
}

.ant-modal-close:hover {
  transform: rotate(180deg) !important;
}

.ant-modal-close {
  transition: 1s !important;
  color: #ff0000e8 !important;
  background: #e3edec !important;
  border-radius: 50% !important;
  font-size: 7px !important;
  border: 1px solid #ff0000e8 !important;
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: bolder !important;
  cursor: pointer !important;
}

.anticon svg {
  display: inline-block !important;
  font-size: 12px !important;
}

.kycPageHeight {
  max-height: calc(100vh - 290px);
  overflow: auto;
}

.action__buttons i {
  color: var(--mainColor);
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action__buttons button:hover i {
  color: var(--whiteColor);
}

.saperate_action_button {
  background: var(--whiteColor);
  padding: 2px 4px;
  display: flex;
  gap: 5px;
  border-radius: 20pc;
  position: absolute;
  right: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) -10px 13px 60px 12px;
}

.action__buttons button {
  height: 18px;
  width: 17px;
  padding: 4px;
  background: transparent;
  border: 1px solid var(--mainColor);
  transition: 1s;
}

.action__buttons button:hover {
  border: 1px solid var(--mainColor);
  background: var(--mainColor);
}

.tableLibrarySection {
  background: var(--borderColor);
}

.react-contextmenu a {
  text-decoration: none;
}

.HomeReturm .dropdown-toggle::after {
  display: none;
}

.react-diff-viewer {
  background-color: #2e2e2e;
  color: #dcdcdc;
}

.react-diff-viewer .diff-gutter {
  background-color: #2e2e2e;
}

.react-diff-viewer .line-number {
  color: #888;
}

.react-diff-viewer .diff-added {
  background-color: #144212;
  color: #9fff9f;
}

.react-diff-viewer .diff-removed {
  background-color: #4f1a1a;
  color: #ff9f9f;
}

.react-diff-viewer .highlighted {
  background-color: #3b3b3b;
}

.react-diff-viewer .marker {
  color: #999;
}

.react-diff-viewer .contentText {
  color: #dcdcdc;
}

.output__section_widget pre {
  margin: 0 !important;
}

.output__section_widget pre code {
  white-space: pre-wrap !important;
}

.logsTextErrorColor pre code {
  color: red;
}

.logsTextSuccessColor pre code {
  color: green;
}

.diffTabHeight {
  height: calc(100vh - 290px);
  overflow: auto;
}

.loader {
  width: 18px;
  height: 18px;
  border: 2px dotted #000;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 2px dotted #000;
  border-style: solid solid dotted;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}

.outPutControl {
  text-align: center;
}

.outPutControl table {
  width: max-content;
  margin: 0 auto;
}

.outPutControl table th {
  text-align: end;
}

.outPutControl table td {
  text-align: left;
}

.pooledTable {
  padding: 10px;
  background: #fff;
  border: 1px solid var(--borderColor);
  border-radius: 8px;
}

.badgesChips {
  display: flex;
  flex-wrap: wrap;
}

.badgesChips .chip {
  border: 1px solid var(--mainColor);
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 3px;
  margin-right: 5px;
}

.badgesChips .chip span {
  font-size: 10px;
  padding: 3px 5px;
  background: var(--mainColor);
  color: var(--whiteColor);
}

.badgesChips .chip i {
  font-size: 10px;
  cursor: pointer;
  padding: 0 5px;
  color: var(--mainColor);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@page {
  margin: 1.5mm 1.5mm 1.5mm 1.5mm;
}

/* HTML: <div class="loader"></div> */
.Saveloader {
  width: 15px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #103c5e;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

.Saveloader2 {
  height: 10px;
  width: 10px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #103c5e;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  mask: var(--_m);
  mask-composite: subtract;
  animation: l3 1s infinite linear;
  margin: 3px 5px;
}

.couponLoader {
  width: 40px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #fff 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l4 1s infinite linear;
}

@keyframes l4 {
  20% {
    background-position:
      0% 0%,
      50% 50%,
      100% 50%;
  }

  40% {
    background-position:
      0% 100%,
      50% 0%,
      100% 50%;
  }

  60% {
    background-position:
      0% 50%,
      50% 100%,
      100% 0%;
  }

  80% {
    background-position:
      0% 50%,
      50% 50%,
      100% 100%;
  }
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.common_css pre {
  margin: 0;
}

.bgChange td {
  background: #f8f8ff;
}

.fontChange td {
  font-size: 12px;
}

.fontChange td .form-check input {
  font-size: 12px !important;
}

.bgChange th,
.OutputToolbar {
  background: rgb(212, 213, 217);
}

.OutputToolbar-Left i {
  cursor: pointer;
}

.OutputToolbar-Left button:hover {
  background-color: transparent !important;
}

.OutputToolbar-Left .dropdown-toggle {
  background: transparent !important;
  border: 0;
}

.bgChange span {
  color: inherit;
}

@media print {
  .printButton {
    display: none;
  }

  .output__section_widget pre code {
    white-space: pre-wrap !important;
  }

  /* Adding higher specificity if needed */
  .output__section_widget>pre>code {
    white-space: pre-wrap !important;
  }
}

@page {
  margin: 3rem 2rem;
}

.printFloatingButton {
  position: fixed;
  bottom: 20px;
  right: 50%;
}

.packageExampleHeight {
  max-height: calc(100vh - 220px);
  overflow: auto;
}

.PopSwalButton {
  font-size: 14px !important;
}

.infoCircle {
  font-size: 6px;
  margin-top: -3px;
  cursor: pointer;
}

.infoCircle i {
  background: #103c5ebd;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.inactiveButton {
  border: 1px solid #103c5e;
  border-radius: 3px;
  background: transparent;
  font-size: 13px;
  padding: 4px 10px;
  transition: 1s;
}

.activeButton {
  background: #103c5e !important;
  border: 1px solid #103c5e !important;
  color: #fff;
}

.add_sponsor_field .input-group-text {
  font-size: 11px;
  background-color: var(--mainColor);
  padding: 3px 8px 0;
  border-radius: 3px;
  border-color: #DEDEDE;
}

.fiftyVh {
  height: 60vh;
  font-size: 15px;
}

.tableRightContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 182px);
}

.crfHight {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 191px);
}

.study_icopn i,
.study_icopn svg {
  background: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(16, 60, 94);
}

.chart__overview .card {
  border-radius: 4px;

  .card-title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  .card-text:last-child {
    font-size: 13px;
  }
}